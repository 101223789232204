<template>
    <div id="ForgetPassword" class="login-form-style">
        <section class="member-title-section-style">
            <h1>パスワード再設定の申し込み</h1>
        </section>
        <ElForm
            :label-position="store.getters.labelPosition"
            label-width="180px"
            ref="emailFormRef"
            :model="emailFormData"
            :rules="emailFormRules"
        >
            <ElFormItem class="f14 f14-mb" label="メールアドレス" prop="email">
                <ElInput v-model="emailFormData.email" type="email"></ElInput>
            </ElFormItem>
            <div class="button-wrap">
                <button class="f18 f16-mb" @click.prevent="submitEmailForm">
                    確認メール送信
                </button>
            </div>
            <div class="sub-button-wrap">
                <router-link
                    :to="{ name: 'MemberSystemLogin' }"
                    class="f14 f12-mb"
                    >ログインへ戻る
                </router-link>
                <br />
                <router-link
                    :to="{ name: 'MemberSystemRegister' }"
                    class="f14 f12-mb"
                    >アカウントを作成</router-link
                >
            </div>
        </ElForm>
    </div>
</template>
<script>
export default {
    name: 'ForgetPassword',
}
</script>
<script setup>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { emailRule } from '@/utils/formRules'
import { forgetPassword } from '@/service/api.js'
import { ElMessageBox } from 'element-plus'
const store = useStore()
const router = useRouter()
const emailFormRef = ref(null)
const emailFormData = reactive({
    email: '',
})
const emailFormRules = {
    email: emailRule,
}
function submitEmailForm() {
    emailFormRef.value.validate(async (valid) => {
        if (valid) {
            await forgetPassword(emailFormData.email).then(() => {
                ElMessageBox.alert(
                    '［お申し込み受付確認メール］が指定したアドレスに届きますので、ご確認ください。',
                    'パスワード再設定の申し込みを受け付けました。',
                    {
                        confirmButtonText: '完了',
                        callback: () => {
                            router.push({
                                name: 'MemberSystemLogin',
                            })
                        },
                    }
                )
            })
        }
    })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
</style>
